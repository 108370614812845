import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CommonProvider } from '../contexts/CommonContext';
import { TenantProvider, useTenant } from '../contexts/TenantContext';
import { setHeight, startLoading, stopLoading, showToast } from '../api/CommonApi';
import { getTenant, getAllTenantMembers, getPrivateRoutes } from '../api/TenantApi';
import { getSubscriptionPlan, getSubscriptionPlanMetrics } from '../api/BillingApi';
import { ResizeObserver } from 'resize-observer';
import { getCurrentUser } from '../api/UserApi';
import { stateChange } from '../api/CommonApi';
import { useCommon } from '../contexts/CommonContext';
import { Tenant } from '../interfaces';
import Subheader from '../components/Subheader';
import { getTenantMember } from '../api/TenantApi';
import { canUserRead } from '../services/PermissionService';
import { useContentLocalizer } from '../hooks/useContentLocalizer';
import { FeatureToggleClientService } from 'feature-toggle-client';
import { localization } from './localization';
import { getAppSettings } from '../utils/appSettings';
import { Routes, usageDataOptions } from '../routes';
import { asideMenuOptions } from '../routes/BillingRoutes';

const App: React.FC = () => {
  return (
    <Router>
      <CommonProvider>
        <TenantProvider>
          <Route
            render={({ history }) => (
              <AppConfiguration history={history}>
                <Routes />
              </AppConfiguration>
            )}
          />
        </TenantProvider>
      </CommonProvider>
    </Router>
  );
};

const AppConfiguration = ({ history, children }) => {
  const {
    setLanguage,
    setConfig,
    setLoggedUser,
    common: { loggedUser },
  } = useCommon();
  const { setTenant } = useTenant();
  const {
    location: { pathname },
  } = history;
  const content = useContentLocalizer(localization);
  const loadingOrganizationError = useMemo(() => content.loadOrganizationError, [content]);

  const updateApplicationState = useCallback(
    (tenant, currentUser, currentMember, settings) => {
      setTenant(tenant);
      setLanguage(currentUser.culture);
      setConfig(settings);
      setLoggedUser({ ...currentUser, ...currentMember });
    },
    [setTenant, setLanguage, setLoggedUser],
  );

  useEffect(() => {
    stateChange(pathname);
  }, [pathname]);

  useEffect(() => {
    const rootDiv = document.getElementById('root');
    const loadConfigurations = async () => {
      try {
        startLoading();

        const settings = await getAppSettings();

        const tenant: Tenant = {
          info: await getTenant(),
          members: [],
          agents: [],
          applications: [],
          privateRoutes: [],
        };

        if (tenant?.info) {
          const currentUser = await getCurrentUser();
          const currentMember = await getTenantMember({ tenantId: tenant.info.id, userIdentity: currentUser.identity });

          const featureToggleInstance = FeatureToggleClientService.getInstance();
          featureToggleInstance.initializeUser(currentUser, settings.LD_CLIENT_SDK_KEY, {
            baseUrl: settings.LD_PROXY_BASE_URL,
            eventsUrl: settings.LD_PROXY_EVENTS_URL,
            streamUrl: settings.LD_PROXY_CLIENT_STREAM,
            streamReconnectDelay: settings.LD_STREAM_RECONNECT_DELAY,
          });

          if (canUserRead('tenant-members', currentMember.roleId)) {
            tenant.subscription = await getSubscriptionPlanMetrics(tenant.info.id);
            tenant.info.plan = await getSubscriptionPlan(tenant.info.id);
            tenant.members = await getAllTenantMembers(tenant.info.id);
          }

          tenant.privateRoutes = await getPrivateRoutes(
            tenant.subscription,
            {
              ...currentUser,
              ...currentMember,
            },
            [...usageDataOptions, ...asideMenuOptions],
          );
          updateApplicationState(tenant, currentUser, currentMember, settings);
        }
      } catch (e) {
        showToast({ type: 'danger', message: loadingOrganizationError });
        throw e;
      } finally {
        stopLoading();
      }
    };

    const documentObserver = new ResizeObserver(() => {
      setHeight(rootDiv.scrollHeight);
    });

    documentObserver.observe(rootDiv);
    loadConfigurations();
  }, [loadingOrganizationError, updateApplicationState]);

  return (
    <>
      <Subheader />
      <div id="main">{loggedUser ? children : ''}</div>
    </>
  );
};

AppConfiguration.propTypes = {
  children: PropTypes.node,
  history: PropTypes.any,
};

export default App;

import React from 'react';
import PropTypes from 'prop-types';
import * as lodashDebounce from 'lodash.debounce';
import { BdsInput, BdsButton} from 'blip-ds/dist/blip-ds-react';

const BlipSearchComponent = ({
  onChange,
  debounce,
  search,
  
}) => {
  
  const debouncedChange = lodashDebounce(value => onChange(value), debounce);
  const persistedDebounceChange = e => {
    const {value} = e.target;
    return debouncedChange(value);
  };

  return <div className="BlipSearch items-center flex">
    <BdsInput icon="search" value={search}  
      onBdsChange={(e) => persistedDebounceChange(e)}  />
    {search.length > 0 && <BdsButton className="ml1 " icon="close" onClick={() => onChange('') } size='standard'/>}
  </div>;
};

BlipSearchComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  debounce: PropTypes.number.isRequired,
};

export { BlipSearchComponent };

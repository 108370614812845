export enum origin {
    EXTERNAL = 'external',
    ASIDEMENU = 'aside-menu',
    PLANOVERVIEW = 'plan-overview',
    DAU = 'dau',
    DAUPERDATE = 'dau-per-date',
    DAUPERCHATBOT = 'dau-per-chatbot',    
    AGENT = 'agent',
    AGENTCONSUMPTION = 'agent-consumption',
    AGENTCONSUMPTIONLIST = 'activated-agent-consumption-list',
    SESSION = 'session',
    SESSIONPERDATE = 'session-per-date',
    SESSIONPERPHONENUMBER = 'session-per-phonenumber',
    MAU = 'mau',
    MIUCONSUMPTION = 'miu-consumption',
    MIUPERCHATBOT = 'miu-per-chatbot',
    REVENUE = 'revenue',
    REVENUEPERCHATBOT = 'revenue-per-chatbot',
}
  
export enum action {
    OPEN = 'open',
    CLICK = 'click',
    HOVER = 'hover',
    SELECT = 'select',
}

export const AGENT_CONSUMPTION_CLICK = 'tenant-contract-agentconsumption-click';
export const AGENT_CONSUMPTION_MONTHLY_BALANCE_STATEMENT_DETAIL_CLICK = 'tenant-contract-agentconsumption-monthly-balance-statement-detail-click';
export const AGENT_CONSUMPTION_PERIOD_SELECT = 'tenant-contract-agentconsumption-period-select';
export const AGENT_CONSUMPTION_PERIOD_SELECT_MONTHLY_OPENED = 'tenant-contract-agentconsumption-period-select-monthly-opened';
export const CHATBOTS_REVENUE_CHATBOTS_CLICK = 'tenant-contract-revenue-chatbots-click';
export const CONTRACT_AGENTS = 'tenant-contract-agents-click';
export const CONTRACT_MEMBERS = 'tenant-contract-members-click';
export const CONTRACT_NAME_CHANGED = 'tenant-contract-name-changed';
export const CONTRACT_REVENUE_CLICK = 'tenant-contract-revenue-click';
export const DAILY_ACTIVE_USERS_CLICK = 'tenant-contract-dailyactiveuser-click';
export const DAILY_ACTIVE_USERS_DAILY_BALANCE_STATEMENT_DETAIL_CLICK = 'tenant-contract-dailyactiveuser-daily-balance-statement-detail-click';
export const DAILY_ACTIVE_USERS_MONTHLY_BALANCE_STATEMENT_DETAIL_CLICK = 'tenant-contract-dailyactiveuser-monthly-balance-statement-detail-click';
export const DAILY_ACTIVE_USERS_PERIOD_SELECT = 'tenant-contract-dailyactiveuser-period-select';
export const DAILY_ACTIVE_USERS_PERIOD_SELECT_DAILY_OPENED = 'tenant-contract-dailyactiveuser-period-select-daily-opened';
export const DAILY_ACTIVE_USERS_PERIOD_SELECT_MONTHLY_OPENED = 'tenant-contract-dailyactiveuser-period-select-monthly-opened';
export const DATA_CONSUMPTION_CLICK = 'tenant-contract-dataconsumption-click';
export const DATA_CONSUMPTION_PERIOD_SELECT = 'tenant-contract-dataconsumption-period-select';
export const LEAVE_CONTRACT_CLICK = 'tenant-leave-contract-click';
export const LEAVE_CONTRACT_SUCCESS = 'tenant-leave-contract-success';
export const MONTH_REVENUE_SELECT = 'tenant-contract-revenue-month-select';
export const ORGANIZATION_AGENTS = 'organization-agents';
export const ORGANIZATION_AGENTS_ADD = 'organization-agents-add';
export const ORGANIZATION_AGENTS_DELETE = 'organization-agents-delete';
export const ORGANIZATION_AGENTS_ENABLED = 'organization-agents-enabled';
export const ORGANIZATION_AGENTS_FILTER_TRIGGERED = 'organization-agents-filter-triggered';
export const ORGANIZATION_AGENTS_SEARCH = 'organization-agents-search';
export const ORGANIZATION_AGENTS_SORT = 'organization-agents-sort';
export const ORGANIZATION_AGENTS_TRIGGERED_DISABLED = 'organization-agents-triggered-disabled';
export const ORGANIZATION_MEMBERS = 'organization-members';
export const ORGANIZATION_MEMBERS_INVITES = 'organization-members-manager-invites';
export const ORGANIZATION_MEMBERS_INVITES_REJECT_CANCEL = 'organization-members-manager-invites-reject-cancel';
export const ORGANIZATION_MEMBERS_INVITES_REJECT_CONFIRM = 'organization-members-manager-invites-reject-confirm';
export const ORGANIZATION_MEMBERS_INVITES_SORT = 'organization-invites-sort';
export const ORGANIZATION_MEMBERS_SEARCH = 'organization-members-search';
export const ORGANIZATION_MEMBERS_SORT = 'organization-members-sort';
export const SESSION_CONSUMPTION_CLICK = 'tenant-contract-sessionconsumption-click';
export const SESSION_CONSUMPTION_DAILY_BALANCE_STATEMENT_DETAIL_CLICK = 'tenant-contract-sessionconsumption-daily-balance-statement-detail-click';
export const SESSION_CONSUMPTION_MONTHLY_BALANCE_STATEMENT_DETAIL_CLICK = 'tenant-contract-sessionconsumption-monthly-balance-statement-detail-click';
export const SESSION_CONSUMPTION_PERIOD_SELECT = 'tenant-contract-sessionconsumption-period-select';
export const SESSION_CONSUMPTION_PERIOD_SELECT_DAILY_OPENED = 'tenant-contract-sessionconsumption-period-select-daily-opened';
export const SESSION_CONSUMPTION_PERIOD_SELECT_MONTHLY_OPENED = 'tenant-contract-sessionconsumption-period-select-monthly-opened';
export const SESSION_CONSUMPTION_TYPE_SELECT = 'tenant-contract-sessionconsumption-type-select';
export const SESSION_CONSUMPTION_TYPE_SELECT_DAILY_OPENED = 'tenant-contract-sessionconsumption-type-select-daily-opened';
export const SESSION_CONSUMPTION_TYPE_SELECT_MONTHLY_OPENED = 'tenant-contract-sessionconsumption-type-select-monthly-opened';
export const SESSION_CONSUMPTION_WABA_SELECT = 'tenant-contract-sessionconsumption-waba-select';
export const SESSION_CONSUMPTION_WABA_SELECT_DAILY_OPENED = 'tenant-contract-sessionconsumption-waba-select-daily-opened';
export const SESSION_CONSUMPTION_WABA_SELECT_MONTHLY_OPENED = 'tenant-contract-sessionconsumption-waba-select-monthly-opened';
export const REPORT_PERIOD_SELECT = 'tenant-consumptionreport-period-select';
export const REPORT_EXPORT_CLICK = 'tenant-consumptionreport-export-click';
export const REPORT_OVERVIEW_CONSUMPTION_OPEN = 'tenant-consumptionreport-planoverview-open';
export const REPORT_AGENT_CONSUMPTION_OPEN = 'tenant-consumptionreport-agent-open';
export const REPORT_AGENT_PLAN_CONSUMPTION_CHART_HOVER = 'tenant-consumptionreport-agentplanconsumptionchart-hover';
export const REPORT_AGENT_TOTAL_PLAN_TOOLTIP_HOVER = 'tenant-consumptionreport-agenttotalplantooltip-hover';
export const REPORT_AGENT_WEEKLY_CONSUMPTION_CHART_HOVER = 'tenant-consumptionreport-agentweeklyconsumptionchart-hover';
export const REPORT_AGENT_CONSUMPTION_LIST_OPEN = 'tenant-consumptionreport-agentactivatedlist-open';
export const REPORT_DAU_CONSUMPTION_OPEN = 'tenant-consumptionreport-dau-open';
export const REPORT_DAU_TOTAL_PLAN_TOOLTIP_HOVER = 'tenant-consumptionreport-dautotalplantooltip-hover';
export const REPORT_DAU_PLAN_CONSUMPTION_CHART_HOVER = 'tenant-consumptionreport-dauplanconsumptionchart-hover';
export const REPORT_DAU_WEEKLY_CONSUMPTION_CHART_HOVER = 'tenant-consumptionreport-dauweeklyconsumptionchart-hover';
export const REPORT_DAU_CONSUMPTION_PER_CHATBOT_OPEN = 'tenant-consumptionreport-dauperchatbot-open';
export const REPORT_DAU_CONSUMPTION_PER_DATE_OPEN = 'tenant-consumptionreport-dauperdate-open';
export const REPORT_REVENUE_CONSUMPTION_OPEN = 'tenant-consumptionreport-revenue-open';
export const REPORT_REVENUE_TITLE_TOOLTIP_HOVER = 'tenant-consumptionreport-revenuetitletooltip-hover';
export const REPORT_REVENUE_PLAN_CONSUMPTION_TOOLTIP_HOVER = 'tenant-consumptionreport-revenueplanconsumptiontooltip-hover';
export const REPORT_REVENUE_PLAN_CONSUMPTION_CHART_HOVER = 'tenant-consumptionreport-revenueplanconsumptionchart-hover';
export const REPORT_REVENUE_WEEKLY_CONSUMPTION_CHART_HOVER = 'tenant-consumptionreport-revenueweeklyconsumptionchart-hover';
export const REPORT_REVENUE_CONSUMPTION_PER_CHATBOT_OPEN = 'tenant-consumptionreport-revenueperchatbot-open';
export const REPORT_SESSION_CONSUMPTION_OPEN = 'tenant-consumptionreport-session-open';
export const REPORT_SESSION_TITLE_TOOLTIP_HOVER = 'tenant-consumptionreport-sessiontitletooltip-hover';
export const REPORT_SESSION_CONSUMPTION_CHART_HOVER = 'tenant-consumptionreport-sessiontotalconsumptionchart-hover';
export const REPORT_SESSION_WEEKLY_CONSUMPTION_CHART_HOVER = 'tenant-consumptionreport-sessionweeklyconsumptionchart-hover';
export const REPORT_SESSION_FREETIER_TOOLTIP_HOVER = 'tenant-consumptionreport-sessionfreetiertooltip-hover';
export const REPORT_SESSION_FREE_ENTRY_POINT_TOOLTIP_HOVER = 'tenant-consumptionreport-sessionfreeentrypointtooltip-hover';
export const REPORT_SESSION_BILLED_TOOLTIP_HOVER = 'tenant-consumptionreport-sessionbilledtooltip-hover';
export const REPORT_SESSION_CONSUMPTION_PER_DATE_OPEN = 'tenant-consumptionreport-sessionperdate-open';
export const REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_OPEN = 'tenant-consumptionreport-sessionperphonenumber-open';
export const REPORT_SESSION_CONSUMPTION_WABAID_SELECT = 'tenant-consumptionreport-sessionwabaid-select';
export const REPORT_SESSION_CONSUMPTION_CONVERSATION_SELECT = 'tenant-consumptionreport-sessionconversationtype-select';
export const REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_BUSINESSINITIATED_TAB_CLICK = 'tenant-consumptionreport-sessionperphonenumber-bi-tab-click';
export const REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_USERINITIATED_TAB_CLICK = 'tenant-consumptionreport-sessionperphonenumber-ui-tab-click';
export const REPORT_SESSION_CONSUMPTION_PER_PHONENUMBER_MOREINFO_CLICK = 'tenant-consumptionreport-sessionperphonenumber-moreinfo-click';
export const REPORT_SESSION_CONSUMPTION_PER_DATE_BUSINESSINITIATED_TAB_CLICK = 'tenant-consumptionreport-sessionperdate-bi-tab-click';
export const REPORT_SESSION_CONSUMPTION_PER_DATE_USERINITIATED_TAB_CLICK = 'tenant-consumptionreport-sessionperdate-ui-tab-click';
export const REPORT_SESSION_CONSUMPTION_PER_DATE_MOREINFO_CLICK = 'tenant-consumptionreport-sessionperdate-moreinfo-click';
import React, { useEffect, useMemo, useState } from 'react';
import { useTenant } from '../../contexts/TenantContext';
import { useCommon } from '../../contexts/CommonContext';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import BlipCard from '../../components/BlipCard';
import Sidebar from '../../components/Sidebar';
import { BdsTypo, BdsTooltip, BdsIcon } from 'blip-ds/dist/blip-ds-react';
import { createTrack } from '../../api/SegmentApi';
import { localization } from './localization';
import { useHistory } from 'react-router-dom';
import FeatureToggleService from '../../services/FeatureToggleService';
import { DATA_CONSUMPTION } from '../../shared/FeatureToggleKeys';
import { ContactHomeContainer, ContactHomeContent, ContactHomeContentRow, ContactHomeOptionContainer } from './styles';
import { generalSettingsOptions } from '../../routes';
import { canUserRead } from '../../services/PermissionService';
import './styles.scss';

const ContactHome: React.FC = () => {
  const {
    common: { loggedUser },
  } = useCommon();
  const history = useHistory();
  const content = useContentLocalizer(localization);
  const {
    tenant: { info: tenant, privateRoutes, subscription },
  } = useTenant();

  const [isGeneralSettingsOptionsVisible, setGeneralSettingsOptionsVisible] = useState(false);
  const [isUsageDataOptionsVisible, setUsageDataOptionsVisible] = useState(false);
  const [isConsumptionReportVisible, setConsumptionReportVisible] = useState(false);

  const onOptionClick = (trackingKey: string, path: string): void => {
    createTrack(tenant, trackingKey, {
      user: loggedUser.email,
      plan: tenant.plan,
      origin: 'contract-panel',
      action: 'click',
    });

    history.push(path);
  };

  const generateMenuOptions = (availableOptions) => {
    return availableOptions.map((item, idx) => (
      <BlipCard
        key={idx}
        icon={item.icon}
        title={content[item.group][item.option].title}
        description={content[item.group][item.option].description}
        onClick={() => onOptionClick(item.segmentEvent, item.path)}
      ></BlipCard>
    ));
  };

  useEffect(() => {
    const getData = async () => {
      const isConsumptionReportEnabled = await FeatureToggleService.isFeatureToggleEnabled(DATA_CONSUMPTION);
      setConsumptionReportVisible(isConsumptionReportEnabled);
    };
    getData();
  }, []);

  const getGeneralSettingsMenuOptions = useMemo(() => {
    const availableGeneralSettingsOptions = generalSettingsOptions.filter((item) => {
      if (item.option === 'agent') {
        return (
          canUserRead(item.accessPermission, loggedUser.roleId) && subscription?.some((s) => s.metricId === item.metric)
        );
      }

      if (item.option === 'members') {
        return canUserRead(item.accessPermission, loggedUser.roleId);
      }
    });

    setGeneralSettingsOptionsVisible(availableGeneralSettingsOptions.length > 0);
    return generateMenuOptions(availableGeneralSettingsOptions);
  }, []);

  const getUsageDataMenuOptions = useMemo(() => {
    let availableUsageDataOptions = privateRoutes.filter((item) => item.group === 'usageData');
    const usageDataOptionsVisible = availableUsageDataOptions.length > 1;
    availableUsageDataOptions = availableUsageDataOptions.filter((item) =>
      isConsumptionReportVisible ? item.option === 'dataConsumption' : item.option !== 'dataConsumption',
    );
    setUsageDataOptionsVisible(usageDataOptionsVisible);
    return generateMenuOptions(availableUsageDataOptions);
  }, [privateRoutes, isConsumptionReportVisible]);

  return (
    <ContactHomeContainer>
      <Sidebar horizontal={!isGeneralSettingsOptionsVisible && !isUsageDataOptionsVisible} />
      <ContactHomeContent>
        {isGeneralSettingsOptionsVisible && (
          <ContactHomeContentRow className="bb-color-surface">
            <BdsTypo variant="fs-24" tag="span" bold="semi-bold" data-test="general-settings-title">
              {content.generalSettings.title}
            </BdsTypo>
            <BdsTooltip position="top-center" tooltip-text={content.generalSettings.info} data-test="general-settings-tooltip">
              <BdsIcon name="info" theme="solid"></BdsIcon>
            </BdsTooltip>
          </ContactHomeContentRow>
        )}
        <ContactHomeOptionContainer>{getGeneralSettingsMenuOptions}</ContactHomeOptionContainer>
        {isUsageDataOptionsVisible && (
          <>
            <ContactHomeContentRow className="bb-color-surface">
              <BdsTypo variant="fs-24" tag="span" bold="semi-bold" data-test="usage-data-title">
                {content.usageData.title}
              </BdsTypo>
              <BdsTooltip position="top-center" tooltip-text={content.usageData.info} data-test="usage-data-tooltip">
                <BdsIcon name="info" theme="solid"></BdsIcon>
              </BdsTooltip>
            </ContactHomeContentRow>
            <ContactHomeOptionContainer>{getUsageDataMenuOptions}</ContactHomeOptionContainer>
          </>
        )}
      </ContactHomeContent>
    </ContactHomeContainer>
  );
};

export default ContactHome;

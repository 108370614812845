import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PORTAL_SUBHEADER_HEIGHT = '55px';

export const SubheaderContainer = styled.div`
  width: 100%;
  padding: 2.5rem  0 2.5rem 0;
  height: ${PORTAL_SUBHEADER_HEIGHT};
  box-shadow: 0px 2px 12px rgba(96, 123, 153, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubheaderContainerLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 8px;

  & > bds-icon {
    cursor: pointer;
    transition: all 0.1s ease-in;
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(1.2);
    }
  }
`;

export const SubheadContentGeneral = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SubheaderContent = styled.div`
  ${(props) => (props.hasMaxWidth ? 'max-width: 1440px; margin: 0 auto;' : '')};
  width: 93%;
  display: flex;
  justify-content: center;
`;


export const SubheaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const SubheaderRight = styled.div`
  display: flex;
`;

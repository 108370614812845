import { IframeMessageProxy } from 'iframe-message-proxy';

export const sendCommand = (command, timeout = 30000) => {
  return IframeMessageProxy.sendMessage({
    action: 'sendCommand',
    content: {
      command,
      timeout,
      destination: 'BlipService',
    },
  });
};

export const BlipApi = {
  sendCommand,
};

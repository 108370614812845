import React from 'react';
import PropTypes from 'prop-types';
import { BlipSearchComponent } from './BlipSearchComponent';

const BlipSearchContainer = ({
  onChange,
  debounce,
  search,
}) => {
  return <BlipSearchComponent
    onChange={onChange}
    debounce={debounce || 0}
    search={search}
  />;
};

BlipSearchContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  debounce: PropTypes.number,
};

export { BlipSearchContainer };
